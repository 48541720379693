import React, {
  Component
} from 'react';


import './faq.css';

class Faq extends Component {


  constructor() {
    super();
  }

  render() {
    return (
        <div className={`Faq-container ${this.props.class}`}>
          <h5>{this.props.name}</h5>
          <p>{this.props.description}</p>
        </div>
    );
  };
}

export default Faq;
