import React, {
  Component
} from 'react';
import '@fortawesome/fontawesome-svg-core/styles.css';
import fontawesome from '@fortawesome/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './App.css';
import Header from './components/header/header';
import Feature from './components/feature/feature';
import Faq from './components/faq/faq';
import Footer from './components/footer/footer';
import SEO from '../components/seo'
// import data from './data.js';
import logo from './images/logo.svg';
import square from './images/square.png';
import mobileLogo from './images/mobile-logo.svg';
import background from './images/background.svg';
import smallBackground from './images/graphic-mobile.png';
import easy from './images/features/easy.svg';
import beautiful from './images/features/beautiful.svg';
import detailed from './images/features/detailed.svg';
import dahsboard from './images/dashboard.svg';
import sellItem from './images/sell-item.svg';
import analytics from './images/analytics.svg';
import purchaseBackground from './images/purchase-background.svg';
import purchaseBackground_1 from './images/Purchase_1.svg';
import purchaseBackground_2 from './images/Purchase_2.svg';
import Cookies from 'universal-cookie';

// import {config} from '@fortawesome/fontawesome-svg-core';
// config.autoAddCss = false;

fontawesome.library.add(
  faArrowRight
)

const goToPage = (url) => {
  window.location.href = url;
}


class App extends Component {
  constructor(props) {
    super(props);
    this.state = { features: [], faq: [], logo: "" };
  }

  componentDidMount() {
    this.resize();
    window.addEventListener("resize", this.resize.bind(this));
    const fbp = `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '740289706514043');
  fbq('track', 'PageView');
    `;
    new Function(fbp)();
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    
    gtag('js', new Date());
  
    gtag('config', 'AW-603630841');
    gtag('event', 'conversion', {'send_to': 'AW-603630841/g6IkCP2zjtsBEPnZ6p8C'});
    // new Function(gad)();
    function gtag_report_conversion(url) {
      var callback = function () {
        if (typeof (url) != 'undefined') {
          window.location = url;
        }
      };
      gtag('event', 'conversion', {
        'send_to': 'AW-603630841/g6IkCP2zjtsBEPnZ6p8C',
        'event_callback': callback
      });
      return false;
    }
    document.getElementById('signupbtn').addEventListener('click', function(e){
      gtag_report_conversion('https://resellanalytics.io/#purchase');
      goToPage('https://dashboard.resellanalytics.io/login');
    });
  }

  resize() {
    if (window.innerWidth <= 750) {
      this.setState({ logo: logo });
    } else {
      this.setState({ logo: logo });
      console.log(this.state);
    }
  }

  render() {
    return (
      <div className="App">
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-603630841"></script>
        <noscript><img height="1" width="1" style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=740289706514043&ev=PageView&noscript=1"
        /></noscript>
        <SEO title="Home" />
        <div className="Background"></div>

        <div className="Background-wrapper">
          <div className="Background-container">
            <img className="Background-graphic" src={background} />
          </div>
        </div>


        <Header logo={this.state.logo} signIn={() => { goToPage('https://dashboard.resellanalytics.io/login') }}></Header>

        <section className="Section Landing-section">
          <div className="Wrapper">
            <h1>Take <span style={{ color: "#2d60f7" }}>control</span> of your reselling business - from anywhere.</h1>
            <p>Introducing <span>ResellAnalytics</span>, a visually appealing, integrated web-app, which helps you stay organized, informed, and sell more effeciently.</p>
            <div onClick={() => { goToPage('#purchase') }} className="Sign-up">Sign up <FontAwesomeIcon className="Sign-up-icon" icon={faArrowRight} /></div>
          </div>


          <div className="Wrapper-small">
          </div>
          <img className="Small-graphic" src={smallBackground} />

        </section>

        <section className="Section Features-section">
          <div className="Features-wrapper">
            <Feature key={0} image={easy} name="Easy To Use" description="ResellAnalytics was made with the user in mind. Its intuitive UI is quick to learn and easy to use." />
            <Feature key={1} image={beautiful} name="Visual" description="From inputting data to viewing statistics, ResellAnalytics was designed to look good. Who wants to use a spreadsheet?" />
            <Feature key={2} image={detailed} name="Detailed" description="Detailed graphs and data will give you insightful information to help you sell more. Focus on cooking, and let ResellAnalytics handle the numbers." />
          </div>
        </section>

        <section className="Section Feature-section First-feature">
          <div className="Wrapper-left">
            <h2>Keep track of everything in one place</h2>
            <div className="Feature-text-wrapper">
              <p><span>ResellAnalytics</span> records detailed statistics about Total Sale Volume, Total Sales, Total Spend, Total Profit, and Inventory. Trash your spreadsheet.</p>
            </div>
          </div>
          <div className="Wrapper-right">
            <img src={dahsboard} />
          </div>
        </section>

        <section className="Section Feature-section desktop">
          <div className="Wrapper-left">
            <img className="Image-left" src={sellItem} />
          </div>
          <div className="Wrapper-right Text-right">
            <h2>Integration with major selling platforms</h2>
            <p>No more sitting at a computer for hours typing in sales information. <span>ResellAnalytics</span> lets you automatically integrate with major third-party platforms to easily transfer listings and sales. Spend more time selling and less time accounting.</p>
          </div>
        </section>

        <section className="Section Feature-section mobile">
          <div className="Wrapper-left">
            <h2>Integration with major selling platforms</h2>
            <p>No more sitting at a computer for hours typing in sales information. <span>ResellAnalytics</span> lets you automatically integrate with major third-party platforms to easily transfer listings and sales. Spend more time selling and less time accounting.</p>
          </div>
          <div className="Wrapper-right">
            <img className="Image-left" src={sellItem} />
          </div>
        </section>

        <section className="Section Feature-section">
          <div className="Wrapper-left">
            <h2>Detailed Analytics</h2>
            <div className="Feature-text-wrapper">
              <p>Seeing your selling history and trends have never been easier. With <span>ResellAnalytics</span> you can track your history and easily view data about certain points in your reselling career.</p>
            </div>
          </div>
          <div className="Wrapper-right">
            <img src={analytics} />
          </div>
        </section>

        <section id="purchase" className="Section Purchase-section">
          <img className="top" src={purchaseBackground_1} />
          <img className="bottom" src={purchaseBackground_2} />
          <div className="Purchase-wrapper">
            <div className="Purchase-left">
              <h2>Purchase</h2>
              <p>Take control of your reselling business today with <span>ResellAnalytics</span></p>
            </div>

            <div className="Purchase-right">
              <div className="Purchase-box">
                <h5>Cost</h5>
                <p className="price">$5/Month</p>
                <p>All devices supported</p>
              </div>
              <button className="sign-up" id="signupbtn">Sign up</button>
            </div>

          </div>

        </section>

        <section className="Section Faq-section">
          <h2>Frequently asked questions</h2>
          <div className="Faq-wrapper">
            <Faq key={0} class="top" name="What is ResellAnalytics?" description='ResellAnalytics is one of the best cloud based application to keep track of everything you do reselling. We keep track of all your current inventory and sales so you have the "need-to-know" numbers to refine your reselling career. Do not settle for anything less.' />
            <Faq key={1} class="bottom" name="Is it safe?" description="Yes! Unlike other sites, Resell Analytics will NEVER ask you to type in your username or password for third party sites like other cloud based apps do. It is not secure to store your passwords in anyones online database, which is why you will never be asked to store it with Resell Analytics. " />
          </div>
          <div className="Faq-wrapper">
            <Faq key={2} class="top" name="This all seems pretty cool, how do I sign up?" description="On the top of the page you can click 'Sign Up' and easily register an account with us. Or you can visit dashboard.resellanalytics.io/register. After that you will be emailed instructions on how to get started!" />
            <Faq key={3} class="bottom" name="This is great! It must be expensive right?" description="The main goal of this app was to make it available to a broad number of resellers. To do this we only charge $5/month! We want all resellers to be able to easily keep track of their reselling career, without breaking the bank." />
          </div>
        </section>

        <Footer logo={logo} />
      </div>
    );
  }
}




export default App;
