import React, {
  Component
} from 'react';

import twitter from '../.././images/twitter.svg';
import './footer.css';


class Footer extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div className="Footer">

        <div className="Branding-container">
          <img src={this.props.logo} />
          <div className="Branding-wrapper">
            <h5>ResellAnalytics</h5>
            <p>Take control of your reselling business.</p>
          </div>
        </div>

        <div className="Links-container">
          <div className="Links-wrapper">
            <h6>Links</h6>
            <p className="Link First-link"><a href="#">Terms of service</a></p>
            <p className="Link"><a href="https://dashboard.resellanalytics.io/register">Register</a></p>
            <p className="Link"><a href="https://dashboard.resellanalytics.io/">Dashboard</a></p>
          </div>
        </div>

        <div className="Links-container">
          <div className="Links-wrapper">
            <h6>Social Media</h6>
            <p className="First-link"><a href="https://twitter.com/ResellAnalytics"><img className="Social-link" src={twitter}/></a></p>
          </div>

        </div>

      </div>
    );
  };
}

export default Footer;
