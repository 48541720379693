import React, {
  Component
} from 'react';


import './feature.css';

class Feature extends Component {


  constructor() {
    super();
  }

  render() {
    return (
        <div className={`Feature-container ${this.props.class}`}>
          <div className="Feature-img">
            <img src={this.props.image} />
          </div>
          <div className="Feature-details">
            <h5>{this.props.name}</h5>
            <p>{this.props.description}</p>
          </div>
        </div>
    );
  };
}

export default Feature;
