import React, {
  Component
} from 'react';


import './header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

class Header extends Component {


  constructor() {
    super();
  }

  render() {
    console.log(this.props.logo)
    return (
      <header className="Header">

        <div className="Header-left">
          <img className="Logo" src={this.props.logo} />
          <p className="Title">ResellAnalytics</p>
        </div>

        <div className="Header-right">
          <label className="Login-text" htmlFor='header-sign-in'>Sign in</label>
          <button id="header-sign-in" onClick={this.props.signIn} className="Login-button"><FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>

      </header>
    );
  };
}

export default Header;
