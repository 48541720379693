import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Landing from "../components/landing"
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import App from '../Containers/App'
import '@fortawesome/fontawesome-svg-core';
import Helmet from 'react-helmet'
const IndexPage = () => (
    <App/>
)

export default IndexPage
